import {writable, get, derived, type Writable} from 'svelte/store';
import api from 'Utility/api';
import {ApiTarget} from 'Types/api';
import {
  apiGetOtpAccessLink,
  apiGetDeal,
  apiGetSiteKey,
  apiPatchUpdateInvestor,
  apiPostInvestor,
} from 'Utility/investorFlow/investorFlowAPI';
import type {PostInvestorParams} from 'Utility/investorFlow/investorFlowAPI';
import {validateNumber} from 'Utility/validation';
import {formatE164} from 'Utility/formatters/phone';
import type {
  InvestorFlowProps,
  Deal,
  Investor,
  PersonalInfo,
  Recaptcha,
  RecaptchaState,
} from 'Utility/investorFlow/entity';
import {
  InvestorState,
  ProductPageExperiment,
} from 'Utility/investorFlow/entity';
import {whiteLabeling} from 'Store/WhiteLabelStore';
import type {AdditionalDocument} from 'Store/ibex/checkouts/investorCheckoutStore';
import {transitionToApplication} from 'Utility/investorFlow/transitionFunction';
import {embeddedWebComponent} from 'Store/embedded/embeddedFlowStore';
import {splitName} from 'Utility/investorFlow/splitName';

//Invitation data
export enum Step {
  PERSONAL_INFO = 1,
  INVESTMENT_AMOUNT = 2,
  INCENTIVE_TIER = 3,
}

//Initial Props
export const apiUrl = writable<URL>(undefined);
export const dealId = writable<string>('');
export const hosted = writable<boolean>(false);
export const displayLogo = writable<boolean>(false);
export const accessLink = writable<URL>(undefined);
export const invitationUid = writable<string>('');
export const recaptchaToken = writable<string>('');
export const showRecaptchaV3 = writable<boolean>(true);
export const initialEmailValue = writable<boolean>(true);
export const amounts = writable<Array<number>>([]);
export const currentStep = writable<number>(Step.PERSONAL_INFO);
export const skipInvestmentAmountStep = writable<boolean>(false);
export const hasSubmittedPromotion = writable<boolean>(false);
// Storing the investor id for returning investors with an active access token
// as it is required to fetch the checkout flow params in the checkout flow page
type StoredInvestorIdStore = Omit<Writable<number>, 'update'> & {
  clearStorage: () => void;
};

const storedInvestorIdKey = 'dealmaker_investorId';
const initInvestorId = (): StoredInvestorIdStore => {
  const store = writable<number>(
    JSON.parse(localStorage.getItem(storedInvestorIdKey))
  );

  const set = (value: number) => {
    localStorage.setItem(storedInvestorIdKey, JSON.stringify(value));
    store.set(value);
  };

  const clearStorage = () => {
    localStorage.removeItem(storedInvestorIdKey);
    store.set(null);
  };

  return {
    set,
    clearStorage,
    subscribe: store.subscribe,
  };
};
export const storedInvestorId = initInvestorId();

//Retrieved data
export const deal = writable<Deal>({
  id: undefined,
  security_type: '',
  price_per_security: undefined,
  minimum_investment: undefined,
  raise_type: '',
  raise_type_code: '',
  singularize_security_type: '',
  currency: '',
  currency_symbol: '',
  title: '',
  company: {
    name: '',
  },
  page_content: {
    body: '',
  },
  preset_amount: {primary: 0, secondary: 0, tertiary: 0},
  setting: {
    id: 0,
    landing_page_phone_number_required: false,
    enable_uk_non_accredited_investors: false,
    international_investors_unqualified: false,
    landing_header: '',
    disclaimer_page_content: '',
    disclaimer_title_content: '',
    incentive_plan_header: '',
    incentive_plan_content: '',
  },
  feature_flags: {
    embedded_web_component: false,
    enable_uk_only_reg_s: false,
    oci_aml_doc_upload: false,
    enhanced_perks_free_shares_enabled: false,
  },
  experiments: {
    product_page_email_conformation: null,
  },
});

export const dealExperiments = derived(deal, $deal => {
  return {
    displayEmailConfirmation:
      $deal.experiments?.product_page_email_conformation ===
      ProductPageExperiment.DISPLAY_EMAIL_CONFIRMATION,
  };
});

//User Input Data
export const personalInfo = writable<PersonalInfo>({
  email: '',
  email_confirmation: '',
  name: '',
  phone: '',
  investment_amount: 0,
  first_name: '',
  last_name: '',
});

//Investor
export const investor = writable<Investor>({
  access_link: null,
  id: undefined,
  state: InvestorState.INVITED,
  investment_amount: undefined,
  number_of_securities: undefined,
  name: '',
  tags: [],
  user: {
    id: undefined,
    email: '',
    phone: '',
  },
  checkout_state: '',
  new_investor: false,
  is_funded: false,
  funds_pending: false,
  is_agreement_reset: false,
  security_price: undefined,
  aeropay_user_exists: false,
  enable_ira_profile: false,
  verification_506c_request: {
    id: undefined,
    state: '',
    reminder_email_note: '',
    verification_method: '',
  },
});

export const jwtToken = writable<string>(null);

export const recaptcha = writable<Recaptcha>({
  sitekey: '',
  sitekey_v3: '',
  render: undefined,
});

// params/props to be passed to the investor checkout flow
export type CheckoutFlowParams = {
  dealId: number;
  memberId: number;
  dealQuicksightUid: string;
  investorEmail: string;
  offeringCircularUrl: string;
  subscriptionAgreementUrl: string;
  isNonPriced: boolean;
  additionalDocuments: AdditionalDocument[];
};

//Error Handling
export const apiError = writable<string>('');
export const postingInvestor = writable<boolean>(false);
export const investorCompleted = writable<boolean>(false);
export const apiRequestBaseUrl = writable<URL>(undefined);
export const dealMakerUrl = writable<URL>(undefined);

//State Changer
const updateDealMakerUrl = (props: InvestorFlowProps) => {
  dealMakerUrl.set(props.dealMakerUrl);
};

const updateApiUrl = (props: InvestorFlowProps) => {
  apiUrl.set(props['apiUrl']);
  apiRequestBaseUrl.set(props['apiUrl']);
};

const updateDealId = (props: InvestorFlowProps) => {
  dealId.set(props['dealId']);
};

const updateHosted = (props: InvestorFlowProps) => {
  hosted.set(props['hosted']);
};

const updateDisplayLogo = (props: InvestorFlowProps) => {
  displayLogo.set(props.displayLogo);
};

const updateAccessLink = (url: URL) => {
  accessLink.set(url);
};

const updatePersonalInfo = (person: PersonalInfo) => {
  personalInfo.set(person);
};

const updateInvitationUid = (props: InvestorFlowProps) => {
  invitationUid.set(props['invitationUid']);
};

const getPresetAmounts = (value: Deal): Array<number> => {
  const amounts = new Set<number>();
  amounts.add(value.preset_amount.primary);
  amounts.add(value.preset_amount.secondary);
  amounts.add(value.preset_amount.tertiary);
  return [...amounts].filter(Boolean);
};

const receiveDealSuccess = async (value: Deal) => {
  const {white_labeling} = value;
  whiteLabeling.set(white_labeling);
  deal.set(value);

  const amountsArray = getPresetAmounts(value);
  amounts.set(amountsArray);
  const investment_amount = amountsArray[0] || 0;

  personalInfo.set({
    ...get(personalInfo),
    investment_amount,
  });

  apiError.set('');
};

const receiveDealError = (error: string) => {
  deal.set(undefined);
  apiError.set(error);
};

const receiveApiInvestorSuccess = (value: Investor) => {
  if (value.access_link && !get(embeddedWebComponent)) {
    const url = new URL(window.location.href);
    url.searchParams.set('accessLink', value.access_link.toString());
    window.history.pushState(null, '', url.toString());
  }
  updateAccessLink(value.access_link);
  investor.set(value);
  storedInvestorId.set(value.id);
  apiError.set('');
};

const receiveMultiPartInvestorSuccess = (value: Investor) => {
  const {allocated_amount, new_investor, access_link, investment_amount} =
    value;

  if (allocated_amount > 0 && investment_amount > 0) {
    const investment_amount_cents = investment_amount * 100;
    personalInfo.update(personalInfo => {
      return {...personalInfo, investment_amount: investment_amount_cents};
    });

    dispatchOnFinishEvent(get(personalInfo), value);
    skipInvestmentAmountStep.set(true);
    return receiveApiInvestorSuccess(value);
  }

  if (!new_investor) {
    return receiveApiInvestorSuccess(value);
  }

  const url = new URL(access_link.toString());
  jwtToken.set(url.searchParams.get('token'));
};

const receiveSiteKeySuccess = (value: Recaptcha) => {
  recaptcha.set(value);
  apiError.set('');
};

const receiveSiteKeyError = (error: string) => {
  recaptcha.set(undefined);
  apiError.set(error);
};

const receiveApiInvestorError = (error: string) => {
  apiError.set(error);
};

export const isLessThanMinInvestment = (
  investmentAmount: number,
  minimumInvestment: number
): boolean => {
  return investmentAmount < minimumInvestment;
};

export const isMoreThanMaxInvestment = (
  investmentAmount: number,
  maximumInvestment: number
): boolean => {
  return maximumInvestment && investmentAmount > maximumInvestment;
};

export const isValidAmount = (
  investment_amount: number,
  price_per_security: number,
  minimum_investment: number
): boolean => {
  return (
    investment_amount !== null &&
    validateNumber(investment_amount.toString()) &&
    Math.floor(investment_amount % price_per_security) === 0 &&
    !isLessThanMinInvestment(investment_amount, minimum_investment)
  );
};

//Actions
export const setInitialProps = (props: InvestorFlowProps) => {
  updateDealMakerUrl(props);
  updateApiUrl(props);
  updateDealId(props);
  updateHosted(props);
  updateDisplayLogo(props);
  updateInvitationUid(props);

  const searchParams = new URLSearchParams(window.location.search);
  // used to persist embedded flow after refresh
  const dmAccessLink = searchParams.get('accessLink');
  const emailAddress = searchParams.get('email_address') ?? '';
  const firstName = searchParams.get('first_name') ?? '';
  const lastName = searchParams.get('last_name') ?? '';
  const fullName = [firstName, lastName].filter(n => n).join(' ');
  const phoneNumber = searchParams.get('phone_number') ?? '';

  updatePersonalInfo({
    email: emailAddress,
    email_confirmation: emailAddress,
    investment_amount: 0,
    name: fullName,
    phone: phoneNumber,
    first_name: firstName,
    last_name: lastName,
  });

  if (dmAccessLink) updateAccessLink(new URL(dmAccessLink));
};

export const resetForms = () => {
  apiError.set('');
  postingInvestor.set(false);
  investorCompleted.set(false);
  updateAccessLink(undefined);

  if (!get(embeddedWebComponent)) {
    const url = new URL(window.location.href);
    url.searchParams.delete('accessLink');
    window.history.replaceState(null, '', url.toString());
  }
};

export const fetchDeal = async () => {
  try {
    const dealObj = await apiGetDeal(get(apiUrl), get(dealId));
    receiveDealSuccess(dealObj);
  } catch (error) {
    receiveDealError(error);
  }
};

export const loadInvestorAccessLink = async (
  investorId: string,
  recaptcha_token: string
) => {
  try {
    const data = await apiGetOtpAccessLink(
      get(apiUrl),
      get(dealId),
      investorId,
      recaptcha_token
    );

    updateAccessLink(data.access_link);
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchSiteKey = async () => {
  try {
    const siteKeyResponse = await apiGetSiteKey(get(apiUrl));
    receiveSiteKeySuccess(siteKeyResponse);
  } catch (error) {
    receiveSiteKeyError(error);
  }
};

export const postInvestor = async (
  recaptchaState: RecaptchaState,
  multiPart = false,
  successHandler: (investorObj: Investor) => void = receiveApiInvestorSuccess
) => {
  try {
    // keeps the investorFlow on v2 product page while posting for step 1
    postingInvestor.set(!multiPart);
    const postParams: PostInvestorParams = buildInvestorParams(
      recaptchaState,
      multiPart
    );
    const investorObj: Investor = await apiPostInvestor(postParams);

    dispatchInvitationAcceptEvent(postParams, investorObj);
    receiveApiInvestorError('');
    successHandler(investorObj);
    postingInvestor.set(false);
    investorCompleted.set(!multiPart || get(skipInvestmentAmountStep));
  } catch (error) {
    postingInvestor.set(false);
    receiveApiInvestorError(error);
    investorCompleted.set(false);
  }
};

export const postInvestorMultiPart = async (recaptchaState: RecaptchaState) => {
  await postInvestor(recaptchaState, true, receiveMultiPartInvestorSuccess);
};

export const patchUpdateInvestor = async () => {
  try {
    postingInvestor.set(true);
    viewState.set(EmbeddedFlowState.POSTING_INVESTOR);

    const investorObj: Investor = await apiPatchUpdateInvestor({
      baseUrl: get(apiUrl),
      dealId: get(dealId),
      token: get(jwtToken),
      investment_amount: Number(get(personalInfo).investment_amount.toFixed(2)),
    });

    dispatchOnFinishEvent(get(personalInfo), investorObj);
    receiveApiInvestorSuccess(investorObj);
    receiveApiInvestorError('');
    investorCompleted.set(true);

    transitionToApplication();
  } catch (error) {
    receiveApiInvestorError(error);
    investorCompleted.set(false);
  } finally {
    postingInvestor.set(false);
  }
};

export const buildInvestorParams = (
  recaptchaState: RecaptchaState,
  multiPart = false
): PostInvestorParams => {
  const tagsString = new URLSearchParams(window.location.search).get('tnames');
  const tags = tagsString ? tagsString.split(',') : [];
  const {email, name, phone, investment_amount} = get(personalInfo);

  return {
    baseUrl: get(apiUrl),
    dealId: get(dealId),
    email: email,
    investment_amount: multiPart ? null : Number(investment_amount.toFixed(2)),
    name: name,
    first_name: get(personalInfo).first_name,
    last_name: get(personalInfo).last_name,
    phone: formatE164(phone),
    recaptcha_token: recaptchaState.recaptcha_token,
    recaptcha_invisible: recaptchaState.recaptcha_invisible,
    tags: tags,
    invitation_uid: get(invitationUid),
  };
};

export const dispatchInvitationAcceptEvent = (
  postParams: PostInvestorParams,
  investor: Investor
): void => {
  const {email, phone, name, investment_amount} = postParams;
  const {user} = investor;

  document.dispatchEvent(
    new CustomEvent('GTM-EVENT:onInvitationAccept', {
      bubbles: true,
      detail: {
        email,
        phone,
        name,
        investment_amount: Number((investment_amount / 100).toFixed(2)),
        user_id: user?.id,
      },
    })
  );
};

export const dispatchOnFinishEvent = (
  postParams: PersonalInfo,
  investor: Investor
): void => {
  const {investment_amount, name, email, phone} = postParams;
  const {user} = investor;
  const [firstName, lastName] = splitName(name);

  document.dispatchEvent(
    new CustomEvent('GTM-EVENT:onFinishInvitationPageV2', {
      bubbles: true,
      detail: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        subtotal_amount: Number((investment_amount / 100).toFixed(2)),
        user_id: user?.id,
      },
    })
  );
};

const showPhoneError = () => {
  const phoneError = writable(false);
  const {subscribe, set} = phoneError;
  return {
    subscribe,
    set,
  };
};

const showAmountError = () => {
  const amountError = writable(false);
  const {subscribe, set} = amountError;
  return {
    subscribe,
    set,
  };
};

// request to get the checkout flow params/props
export const getCheckoutFlowParams = async (): Promise<CheckoutFlowParams> => {
  const currentDealId = get(dealId);
  const currentInvestor = get(investor);
  const currentStoredInvestorId = get(storedInvestorId);
  return await api.getJson<CheckoutFlowParams>(
    ApiTarget.Internal,
    `deals/${currentDealId}/investors/${
      currentInvestor.id ?? currentStoredInvestorId
    }/checkout_flow_params`
  );
};

// the list of the pages to be rendered in the investor flow
export enum EmbeddedFlowState {
  PRODUCT_PAGE = 0,
  LOADING_SPINNER = 1,
  QUESTION_FLOW = 2,
  POSTING_INVESTOR = 3,
  RECAPTCHA = 4,
  ACCESS_LINK = 5,
  AUTHENTICATION = 6,
  EMBEDDED_CHECKOUT_FLOW = 7,
  EMBED_COMPONENT = 8,
}

// viewState will hold the page we are going to render in the investor flow
export const viewState = writable<EmbeddedFlowState>(null);
export const publicToken = writable<string>('');
export const phoneTooltip = showPhoneError();
export const amountTooltip = showAmountError();
