import type {InvestorCheckoutState} from 'Store/ibex/checkouts/sectionsStore';

export type InvestorFlowProps = {
  dealMakerUrl: URL;
  apiUrl: URL;
  dealId: string;
  hosted: boolean;
  displayLogo: boolean;
  invitationUid: string;
};

export type Company = {
  id?: number;
  logo?: URL;
  name?: string;
  favicon_image?: URL;
};

export type Banner = {
  regular?: string;
  full?: string;
};

export type WhiteLabeling = {
  banner_image?: Banner;
  primary_logo?: string;
  primary_color?: string;
  primary_color_rgb?: {
    primary_r: number;
    primary_g: number;
    primary_b: number;
  };
  background_image?: string;
  custom_favicon?: string;
};

export type PageContent = {
  body?: string;
};

export type PresetAmount = {
  primary: number;
  secondary: number;
  tertiary: number;
};

export type GTMEventData = {
  investor_id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  zip_code?: string;
  phone?: string;
  subtotal_amount?: number;
};

export type Deal = {
  id: number | undefined;
  security_type: string;
  price_per_security: number | undefined;
  minimum_investment: number | undefined;
  maximum_investment?: number;
  currency: string;
  currency_symbol: string;
  title: string;
  raise_type: string;
  raise_type_code: string;
  singularize_security_type?: string;
  company?: Company;
  white_labeling?: WhiteLabeling;
  title_content?: string;
  page_content?: PageContent;
  preset_amount: PresetAmount;
  document_package_id?: number;
  feature_flags?: DealFeatureFlags;
  experiments?: Experiments;
  setting?: Setting;
  prevent_payments?: boolean;
  redirect_url?: string;
  thank_you_page_url?: string;
};

export type Setting = {
  id: number;
  landing_page_phone_number_required: boolean;
  enable_uk_non_accredited_investors: boolean;
  international_investors_unqualified: boolean;
  landing_header?: string;
  disclaimer_page_content: string;
  disclaimer_title_content: string;
  incentive_plan_header: string;
  incentive_plan_content?: string;
};

export type Recaptcha = {
  sitekey: string;
  sitekey_v3: string;
  render: boolean;
};

export type RecaptchaState = {
  recaptcha_token: string;
  recaptcha_invisible: boolean;
};

export type User = {
  id: number | undefined;
  email: string;
  phone: string;
};

export enum InvestorState {
  DRAFT = 'draft',
  INVITED = 'invited',
  COSIGNING = 'cosigning',
  SIGNED = 'signed',
  WAITING = 'waiting',
  ACCEPTED = 'accepted',
  INACTIVE = 'inactive',
  PROCESSING_COUNTERSIGN = 'processing_countersign',
}

export type Verification506cRequest = {
  id: number;
  state: string;
  reminder_email_note: string;
  verification_method: string;
};

export type Investor = {
  access_link: URL | null;
  id: number | undefined;
  state: InvestorState;
  investment_amount: number | undefined;
  allocated_amount?: number;
  number_of_securities: number | undefined;
  name: string;
  tags: string[];
  user: User;
  checkout_state: InvestorCheckoutState | string;
  phone_number?: string;
  subscription_id?: number;
  status?: string;
  new_investor?: boolean;
  skip_terms_and_conditions?: boolean;
  skip_oci_accreditation_section?: boolean;
  is_funded: boolean;
  funds_pending: boolean;
  enough_total_funds_plus_pending_to_be_funded?: boolean;
  is_agreement_reset: boolean;
  investor_profile_id?: number | undefined;
  postalCode?: string;
  security_price: number;
  aeropay_user_exists: boolean;
  enable_ira_profile: boolean;
  verification_506c_request: Verification506cRequest;
};

export type PersonalInfo = {
  email: string;
  email_confirmation?: string;
  name: string;
  phone: string;
  investment_amount: number;
  first_name: string;
  last_name: string;
};

export type DealFeatureFlags = {
  enable_us_only_reg_a?: boolean;
  enable_ca_unqualified?: boolean;
  enable_uk_only_reg_s?: boolean;
  hide_flow_unit_converter?: boolean;
  enable_trust_profile?: boolean;
  oci_aml_doc_upload?: boolean;
  document_ai_verification_enabled?: boolean;
  embedded_web_component?: boolean;
  enable_506c_improvements?: boolean;
  enhanced_perks_free_shares_enabled?: boolean;
  enhanced_perks_free_shares_codes_enabled?: boolean;
};

export type InvestorOtpAccessLink = {
  access_link: URL;
};

export enum ProductPageExperiment {
  DISPLAY_EMAIL_CONFIRMATION = 'display_email_confirmation',
  NO_EMAIL_CONFIRMATION = 'no_email_confirmation',
}

export type Experiments = {
  product_page_email_conformation?: ProductPageExperiment;
};
